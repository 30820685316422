/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';

// ProtoTypes
import PropTypes from 'prop-types';

// UI lib component
import { Container } from 'react-grid-system';

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                             Section Title                                  */
/* -------------------------------------------------------------------------- */
function SectionTitle({ title }) {
  /* ******************************** RENDERING ******************************* */
  return (
    <Container className="title fluid">
      <h1
        className={`${
          // eslint-disable-next-line no-nested-ternary
          title === 'Evenements'
            ? 'Événements'
            : title === 'Appels à projets'
            ? 'projects'
            : 'default-title'
        }`}
      >
        {title || 'default link'}
      </h1>
    </Container>
  );
}

SectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default SectionTitle;
