/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';

// ProtoTypes
import PropTypes from 'prop-types';

// UI lib component
import { Container } from 'react-grid-system';

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                             Section Title                                  */
/* -------------------------------------------------------------------------- */
function ActualityItem({ image, title }) {
  /* ******************************** RENDERING ******************************* */
  return (
    <Container className="index-actuality-container flex flex-column items-center fluid">
      {image ? (
        <>
          <img src={image} alt={title} className="ob-cover" />
          <h2>{title}</h2>
        </>
      ) : (
        <h2 className="h100 flex items-center">{title}</h2>
      )}
    </Container>
  );
}

ActualityItem.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default ActualityItem;
