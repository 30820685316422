/* eslint-disable prefer-const */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useState, useEffect } from 'react';
import axios from 'axios';

// UI lib component
import { Container } from 'react-grid-system';

// UI local component
import EventItem from '../../single-event';

// API link

import { EVENTS_HOMEPAGE, JALIOS_EVENTS } from '../../../../shared/APIs';

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                  Projects Section Of Index Page                            */
/* -------------------------------------------------------------------------- */

function EventsComponent() {
  /* --------------------------------- HOOKS -------------------------------- */
  const [eventsDataStrapi, setEventsDataStrapi] = useState();
  const [eventsDataJalios, setEventsDataJalios] = useState();
  const [loader, setLoader] = useState(true);

  /* -------------------------------- HELPERS ------------------------------- */
  async function getJaliosEvents() {
    try {
      const { data } = await axios(JALIOS_EVENTS, {
        headers: {
          Accept: 'application/json',
        },
      });
      setEventsDataJalios(data.dataSet);
    } catch (error) {
      setEventsDataJalios([]);
    }
  }
  // GET ALL EVENTS
  useEffect(() => {
    axios
      .get(EVENTS_HOMEPAGE())
      .then(({ data: { data } }) => setEventsDataStrapi(data))
      .then(() => setLoader(false))
      .catch((error) => error);
    getJaliosEvents();
  }, []);
  /* --------------------------------- HELPERS -------------------------------- */
  const jaliosUgpoEvents = eventsDataJalios?.filter((event) => {
    return event.class === 'generated.EvenementUGPO';
  });
  const eventsStrapiPreSorted = eventsDataStrapi?.map(
    ({ attributes: { date } }, index) => {
      const timestamp = new Date(date).getTime();
      return { type: 'strapi', timestamp, index };
    },
  );
  const eventsJaliosPreSorted = jaliosUgpoEvents?.map(
    ({ startDate }, index) => {
      const timestamp = new Date(startDate).getTime();
      return { type: 'jalios', timestamp, index };
    },
  );
  let sortedEvents = [];
  if (
    eventsJaliosPreSorted !== undefined &&
    eventsStrapiPreSorted !== undefined
  ) {
    const eventsArray = [
      ...eventsJaliosPreSorted,
      ...eventsStrapiPreSorted,
    ].sort((a, b) => {
      return b.timestamp - a.timestamp;
    });
    sortedEvents.push(...eventsArray);
  }

  /* -------------------------------- CONST --------------------------------- */

  // MAP EVENTS
  const slicedEvents = sortedEvents?.slice(0, 3)?.map(({ type, index }) => {
    if (type === 'strapi') {
      return (
        <Container className="index-event-wrapper fluid">
          <EventItem event={eventsDataStrapi[index]} source="strapi" />
        </Container>
      );
    }
    if (type === 'jalios') {
      return (
        <Container className="index-event-wrapper fluid">
          <EventItem event={jaliosUgpoEvents[index]} source="jalios" />
        </Container>
      );
    }
  });
  /* ******************************** RENDERING ******************************* */
  return (
    <Container className="index-events-box flex justify-between items-center">
      {loader ? <Container className="loader fluid" /> : slicedEvents}
    </Container>
  );
}

export default EventsComponent;
