/* eslint-disable no-inner-declarations */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import axios from 'axios';

// ProtoTypes
import PropTypes from 'prop-types';

// UI lib component
import { Container } from 'react-grid-system';

// API link
import { LINK_API, EVENT_JALIOS_IMAGE_SOURCE } from '../../../shared/APIs';

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                             Section Title                                  */
/* -------------------------------------------------------------------------- */
function EventItem({ event, source }) {
  /* -------------------------------- CONSTS -------------------------------- */
  const [imageSource, setImageSource] = useState();
  const MONTHS = [
    'Jan',
    'Fév',
    'Mar',
    'Avr',
    'Mai',
    'Jui',
    'Juil',
    'Aou',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  if (source === 'strapi') {
    const DATE = new Date(event?.attributes?.date);

    const MONTH_IN_NUMBER = DATE.getMonth();
    const MONTH_IN_LETTERS = MONTHS[MONTH_IN_NUMBER];

    const DAY = event?.attributes?.date?.split('-')[2];
    const YEAR = event?.attributes?.date?.split('-')[0];

    /* ------------------------------- HELPERS ------------------------------ */

    /* ****************************** RENDERING ****************************** */
    return (
      <Container className="index-event-container flex flex-column fluid">
        <Container className="index-event-container__content fluid">
          <div className="flex justify-start Date">
            <h4>{`${DAY} ${MONTH_IN_LETTERS}, ${YEAR}`}</h4>
          </div>
          <h5>{event?.attributes?.title}</h5>
          <Link to="/details-event" state={{ Source: 'Strapi', event }}>
            <h6 className="read_more">En savoir plus</h6>
          </Link>
        </Container>
        <div className="image__Container">
          {event?.attributes?.picture?.data?.length === 1 ? (
            <img
              src={
                LINK_API + event?.attributes?.picture?.data[0]?.attributes?.url
              }
              alt={event?.attributes?.title}
            />
          ) : (
            event?.attributes?.picture?.data?.length > 1 && (
              <img
                src={
                  LINK_API +
                  event?.attributes?.picture?.data[1]?.attributes?.url
                }
                alt={event?.attributes?.title}
              />
            )
          )}
          <div className="overlay" />
        </div>
      </Container>
    );
  }
  if (source === 'jalios') {
    async function getImageAPI() {
      if (Object.hasOwn(event, 'documentsDBID')) {
        try {
          const { data } = await axios(
            EVENT_JALIOS_IMAGE_SOURCE(event?.documentsDBID[0]),
            {
              headers: {
                Accept: 'application/json',
              },
            },
          );
          setImageSource(data);
        } catch (error) {
          setImageSource();
        }
      } else {
        setImageSource(undefined);
      }
    }
    // Effect
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      getImageAPI();
    }, []);

    const DATE = new Date(event?.startDate);

    const MONTH_IN_NUMBER = DATE.getMonth();
    const MONTH_IN_LETTERS = MONTHS[MONTH_IN_NUMBER];

    const DAY = DATE.getDate();
    const YEAR = event?.startDate.split('-')[0];
    return (
      <Container className="index-event-container flex flex-column fluid">
        <Container className="index-event-container__content fluid">
          <div className="flex justify-start Date">
            <h4>{`${DAY} ${MONTH_IN_LETTERS}, ${YEAR}`}</h4>
          </div>
          <h5>{event?.title}</h5>
          <Link to="/details-event" state={{ Source: 'Jalios', event }}>
            <h6 className="read_more">En savoir plus</h6>
          </Link>
        </Container>
        <div className="image__Container">
          {imageSource === undefined ? (
            <img
              src="https://admin522.horizon-europe.tn/uploads/img_e354b67c1f.jpg"
              alt=""
              className="img-jalios"
            />
          ) : (
            <img
              src={`https://ugpo.horizon-europe.tn/${imageSource?.allFilePath}`}
              alt=""
            />
          )}

          <div className="overlay" />
        </div>
      </Container>
    );
  }
}

EventItem.propTypes = {
  event: PropTypes.objectOf(PropTypes.object).isRequired,
  source: PropTypes.string.isRequired,
};

export default EventItem;
