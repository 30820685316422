/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useState, useEffect } from 'react';
import { navigate, Link } from 'gatsby';
import Carousel, { consts } from 'react-elastic-carousel';

// axios
import axios from 'axios';
// Proptypes
import PropTypes from 'prop-types';

// UI lib component
import { Container, useScreenClass } from 'react-grid-system';

// UI local component
import SectionTitle from '../section-title';
import ActualityItem from '../single-actuality';

// Fake APIS
import {
  API_JALIOS_ACTUALITIES,
  JALIOS_MEDIA_UPLOADS,
} from '../../../shared/APIs/index';
// Assets
import PREV_ICON from '../../../images/index/actualities-section/prev.png';
import NEXT_ICON from '../../../images/index/actualities-section/next.png';
import IMAGE_TEST from '../../../images/Actualities-page/test-image.jpg';

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                  Projects Section Of Index Page                            */
/* -------------------------------------------------------------------------- */

function ActualitiesSectionIndex() {
  /* -------------------------------- HOOKS --------------------------------- */
  const [ActualitiesData, setActualitiesData] = useState();
  const [jaliosDataActualities, setJaliosDataActualities] = useState();

  /* -------------------------------- CONSTS -------------------------------- */
  const screenClass = useScreenClass();

  /* ------------------------- strapi actualities URL ------------------------- */
  // eslint-disable-next-line operator-linebreak
  const url =
    'https://admin522.horizon-europe.tn/api/news?sort[0]=publishedAt%3Adesc&[populate]=*';

  const Arrows = ({ type, onClick, isEdge }) => {
    // eslint-disable-next-line operator-linebreak
    const pointer =
      type === consts.PREV ? (
        <img src={PREV_ICON} alt="previous_icon" />
      ) : (
        <img src={NEXT_ICON} alt="next_icon" />
      );
    return (
      <button type="submit" onClick={onClick} disabled={isEdge}>
        {pointer}
      </button>
    );
  };
  /* -------------------------- Fetch ActualitiesData ------------------------- */

  async function fetchActualitiesData() {
    try {
      const { data } = await axios(url);
      setActualitiesData(data);
    } catch (error) {
      setActualitiesData([]);
    }
  }
  async function fetchJaliosActualities() {
    try {
      const { data } = await axios(API_JALIOS_ACTUALITIES, {
        headers: {
          Accept: 'application/json',
        },
      });
      setJaliosDataActualities(data.dataSet);
    } catch (error) {
      setJaliosDataActualities([]);
    }
  }
  /* ----------------------------- Use effect Hook ---------------------------- */
  useEffect(() => {
    fetchActualitiesData();
    fetchJaliosActualities();
  }, []);
  Arrows.propTypes = {
    type: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    isEdge: PropTypes.bool.isRequired,
  };

  /* ******************************** CALLBACKS ******************************* */
  function Navigation(Source, actuality) {
    navigate('/details-actualite', {
      state: { Source, actuality },
    });
  }
  /* -------------------------------- HELPERS ------------------------------- */

  // sorted section logic
  const actualitiesStrapiPreSort = ActualitiesData?.data?.map(
    ({ attributes: { date } }, index) => {
      const timestamp = new Date(date).getTime();
      return { type: 'Strapi', timestamp, index };
    },
  );
  const jaliosDataActualitiesPreSort = jaliosDataActualities?.map(
    ({ udate }, index) => {
      const timestamp = new Date(udate).getTime();
      return { type: 'Jalios', timestamp, index };
    },
  );
  const sortedActualities = [];
  if (actualitiesStrapiPreSort && jaliosDataActualitiesPreSort) {
    const actualitiesArray = [
      ...actualitiesStrapiPreSort,
      ...jaliosDataActualitiesPreSort,
    ].sort((a, b) => {
      return b.timestamp - a.timestamp;
    });
    sortedActualities.push(...actualitiesArray);
  }
  function renderAllActualities() {
    return sortedActualities?.map(({ type, index }) => {
      if (type === 'Strapi') {
        const actuality = ActualitiesData?.data[index];
        return (
          <Container
            className="index-actuality-wrapper fluid"
            onClick={() => Navigation('Strapi', actuality)}
          >
            <ActualityItem
              // eslint-disable-next-line max-len
              image={`https://admin522.horizon-europe.tn${actuality?.attributes?.picture?.data[0]?.attributes.url}`}
              title={actuality?.attributes?.title}
            />
          </Container>
        );
      }
      if (type === 'Jalios') {
        const actuality = jaliosDataActualities[index];
        const { title, picture } = actuality;
        const test = JALIOS_MEDIA_UPLOADS(picture).length;
        const renderedImage = test > 31 ? JALIOS_MEDIA_UPLOADS(picture) : null;
        return (
          <Container
            className="index-actuality-wrapper fluid"
            key={index}
            onClick={() => Navigation('Jalios', actuality)}
          >
            <ActualityItem image={renderedImage || IMAGE_TEST} title={title} />
          </Container>
        );
      }
      return null;
    });
  }
  // finish sorting

  /* ******************************** RENDERING ******************************* */
  return (
    <>
      <Container id="actualities" className="actualities-section max-w">
        <Container className="section-title-container">
          <SectionTitle title="Actualités" />
        </Container>
        <Container className="index-actuality-box max-w fluid">
          <Carousel
            // enableAutoPlay
            autoPlaySpeed={5000}
            enableSwipe
            enableMouseSwipe
            showArrows
            pagination={false}
            renderArrow={Arrows}
            itemsToShow={['xs', 'sm', 'md'].includes(screenClass) ? 1 : 3}
          >
            {renderAllActualities()}
          </Carousel>
          <Link to="/actualities">
            <h6 className="read_more">Plus d&apos;actualités</h6>
          </Link>
        </Container>
      </Container>
    </>
  );
}

export default ActualitiesSectionIndex;
